<template>
  <el-dropdown
      @visible-change="onVisibleChange"
      class="right-menu-item"
      placement="bottom-start"
      trigger="click"
  >
    <span>
      <i
          class="el-icon-star-on pointer"
          style="font-size: 29px; color: white"
      />
    </span>
    <el-dropdown-menu
        class="p-0"
        slot="dropdown"
        style="min-width: 250px; max-width: 500px"
    >
      <el-tabs
          class="favorite-dropdown--tabs"
          type="border-card"
          v-loading="!projects.length && loading"
      >
        <el-tab-pane :label="$t('system.projects')">
          <template v-if="projects.length">
            <router-link
                :key="project.id"
                :to="{name: 'Overview', params: {projectId: project.id}}"
                v-for="project in projects"
            >
              <el-dropdown-item>
                      <span class="hide-wrap">
                        <strong>#{{ project.id }}</strong> {{ project.subject }}
                      </span>
              </el-dropdown-item>
            </router-link>
          </template>
          <template v-else>
            <el-dropdown-item disabled>
              {{ $t('system.no_pinned_project') }}
            </el-dropdown-item>
          </template>
        </el-tab-pane>
        <!--        <el-tab-pane :label="$t('system.issues')">-->
        <!--          issues-->
        <!--        </el-tab-pane>-->
      </el-tabs>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'FavotiteProjects',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('favorite', ['projects']),
  },
  methods: {
    ...mapActions('favorite', ['fetchFavorite']),
    async onVisibleChange(isVisible) {
      if (isVisible) {
        this.loading = true;
        await this.fetchFavorite();
        this.loading = false;
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.favorite-dropdown--tabs {
  .el-tabs__content {
    max-height: 300px;
    overflow-y: auto;
    padding: 0 !important;

    .el-dropdown-menu__item {
      line-height: 1.5;
      padding: 5px 15px;
    }
  }
}
</style>
