<template>
  <!--  <div>-->
  <el-color-picker
      class="theme-picker"
      popper-class="theme-picker-dropdown"
      v-model="theme"
  />
  <!--    {{theme}}-->
  <!--  </div>-->
</template>

<script>
const ORIGINAL_THEME = '#409EFF'; // default color

export default {
  data() {
    return {
      chalk: '',
      theme: ORIGINAL_THEME,
    };
  },
  mounted() {
    this.theme = (this.$store.state.app.color === '#409EFF') ? '#550781' : this.$store.state.app.color;
  },
  watch: {
    theme: function (val, oldVal) {
      let tmpOldVal = oldVal;
      if (typeof val !== 'string') return;
      this.$store.dispatch('toggleColor', {color: val});
      if (typeof oldVal === 'undefined') tmpOldVal = ORIGINAL_THEME;

      const themeCluster = this.getThemeCluster(val.replace('#', ''));
      const originalCluster = this.getThemeCluster(tmpOldVal.replace('#', ''));

      const styles = [].slice.call(document.querySelectorAll('style'))
          .filter(style => {
            const text = style.innerText;
            return new RegExp(tmpOldVal, 'i').test(text) && !/Chalk Variables/.test(text);
          });
      styles.forEach(style => {
        const {innerText} = style;
        if (typeof innerText !== 'string') return;
        style.innerText = this.updateStyle(innerText, originalCluster, themeCluster);
      });
    },
  },

  methods: {
    updateStyle(style, oldCluster, newCluster) {
      let newStyle = style;
      oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index]);
      });
      return newStyle;
    },

    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16);
        let green = parseInt(color.slice(2, 4), 16);
        let blue = parseInt(color.slice(4, 6), 16);

        if (tint === 0) { // when primary color is in its rgb space
          return [red, green, blue].join(',');
        } else {
          red += Math.round(tint * (255 - red));
          green += Math.round(tint * (255 - green));
          blue += Math.round(tint * (255 - blue));

          red = red.toString(16);
          green = green.toString(16);
          blue = blue.toString(16);

          return `#${red}${green}${blue}`;
        }
      };

      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16);
        let green = parseInt(color.slice(2, 4), 16);
        let blue = parseInt(color.slice(4, 6), 16);

        red = Math.round((1 - shade) * red);
        green = Math.round((1 - shade) * green);
        blue = Math.round((1 - shade) * blue);

        red = red.toString(16);
        green = green.toString(16);
        blue = blue.toString(16);

        return `#${red}${green}${blue}`;
      };

      const clusters = [theme];
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
      }
      clusters.push(shadeColor(theme, 0.1));
      return clusters;
    },
  },
};
</script>

<style lang="scss">
.theme-picker .el-color-picker__trigger {
  vertical-align: middle;
  height: 24px;
  width: 24px;
  padding: 0 !important;
  border: none !important;
}

.theme-picker-dropdown .el-color-dropdown__link-btn {
  display: none;
}
</style>
